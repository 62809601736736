<template>
    <v-container fluid>
        <v-form @submit.prevent="getCategoryShops">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'category_shop.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="1" sm="1" style="max-width: 50px">
                                <v-btn :to="{name: 'dashboard'}"  class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-subheader class="headline">{{ $t('search_category_shop') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'category_shop.create'}" dark color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="8">
                                <ValidationProvider ref="category_shop" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="category_shop" type="text" :error-messages="errors"
                                                  :disabled="loading" :label="$t('category_shop_name')"
                                                  prepend-icon="mdi-folder-text-outline"
                                                  color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="4">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="category_shopItems" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="getCategoryProduct(item)" :title="$t('category_product')">
                                    mdi-folder-outline
                                </v-icon>
                            </template>
                            <span>{{$t('category_product')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on"  @click="editCategoryShop(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  :disabled="item.deleted" @click="deleteCategoryShop(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"

export default {
    name: "CategoryShops",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            category_shop: null,
            sortBy: "name",
            sortDir: true,
            loading: false,
            category_shopItems: [],
            active: null,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 105,
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 150,
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage']),
    },
    mounted() {
        this.getCategoryShops()
    },
    methods: {
        editCategoryShop(item) {
            this.$router.push({
                name: 'category_shop.edit',
                params: {
                    id: item.id
                }
            })
        },
        getCategoryProduct(item) {
            this.$router.push({
                name: 'category_products_filter',
                params: {
                    category_shop: item.id
                }
            })
        },

        async getCategoryShops() {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.category_shop) {
                params.category_shop = this.category_shop
            }
            params.active = this.active
            await this.$http
                .get("partner/category_shop", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.category_shopItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_category_shops'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteCategoryShop(item) {
            if (confirm(this.$t('delete_category_shop'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`partner/category_shop/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('category_shop_has_been_deleted'))
                        this.getCategoryShops()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('category_shop_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
